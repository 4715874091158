<template>
    <div id="matching-filter">
        <b-row>
            <b-col cols="12" sm="8" class="text-left matching-filter">
                <b-row>
                    <b-col cols="12" sm="10">
                        <label>Company</label>
                        <b-form-select value-field="id" text-field="description" v-model="selectedCompany"
                            :options="optionsCompanies" size="sm" />
                    </b-col>
                    <!-- <b-col cols="12" sm="4">
                        <label>Type</label>
                        <b-form-select v-model="selectedType" :options="optionsTypes" size="sm" />
                    </b-col>
                    <b-col cols="12" sm="4">
                        <label>Vendor</label>
                        <b-form-select v-model="selectedVendor" :options="optionsVendors" size="sm" />
                    </b-col> -->
                </b-row>
            </b-col>
            <b-col cols="12" sm="4" class="text-right">
                <b-button size="sm" variant="success" class="mt-2" v-if="isMobileDevice()" block
                    @click="collapseVisible = false; $emit('filterClicked', { selectedCompany: selectedCompany })">
                    <feather-icon icon="FilterIcon" />
                    Recherche
                </b-button>
                <b-button size="sm" variant="success" class="mt-2" v-else
                    @click="collapseVisible = false; $emit('filterClicked', { selectedCompany: selectedCompany })">
                    <feather-icon icon="FilterIcon" class="mr-50 ml-50" />
                    Recherche
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            optionsCompanies: [],
            selectedCompany: null,
            // optionsTypes: [
            //     "All",
            //     "MED",
            //     "PARA"
            // ],
            // selectedType: "All",
            // optionsVendors: [],
            // selectedVendor: "All",
        }
    },
    methods: {
        isMobileDevice() {
            return window.innerWidth <= 769;
        },
    },
    async created() {
        let response = await this.$http.get("company");
        this.optionsCompanies = response.data;
        // this.optionsCompanies.unshift({ description: "All", id: 0 });
        // let responseVendors = await this.$http.get("_vendor");
        // this.optionsVendors = responseVendors.data.map(item => item.entityName);
        // this.optionsVendors.unshift("All");
    }
}
</script>




<style lang="scss">
@media (max-width: 769px) {
    .matching-filter {
        padding-top: 1rem;
        /* Adjust the margin-top value as needed */
    }
}
</style>