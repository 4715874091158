<template>
    <div id="supervisor-matching-items">

        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid ref="agGrid" :withFilters="true" :filterComponent="MatchingItemsDateFilter" :agGrid="agGrid"
                    pageTitle="MATCHING-ITEMS" @filterClicked="filterClicked" @cellDoubleClicked="onItemClicked"
                    @onCellClicked="onCellClicked" />
            </b-card>
        </b-overlay>

    </div>
</template>

<script>
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import MatchingItemsDateFilter from "./MatchingItemsDateFilter.vue"
import BLinkCellRenderer from "@/views/components/ag-grid/BLinkCellRenderer.vue";


export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            MatchingItemsDateFilter: MatchingItemsDateFilter,
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Description",
                        field: "description",
                        cellRendererFramework: 'bLinkCellRenderer',
                        cellRendererParams: { onButtonClick: this.onItemClicked, },
                        width: 250,
                    },
                    {
                        headerName: "Type",
                        field: "type",
                        width: 100,
                    },
                    {
                        headerName: "Vendor",
                        field: "vendor",
                        width: 150,
                    },
                    {
                        headerName: "Code",
                        field: "code",
                        width: 150,
                    },
                    {
                        headerName: "No",
                        field: "no",
                        width: 100,
                        editable: true
                    },
                ],
                rows: []
            },
        }
    },
    created() {
        this.$options.components.bLinkCellRenderer = BLinkCellRenderer;
        setTimeout(() => {
            this.agGrid.rows = [];
            const gridApi = this.$refs.agGrid.gridApi;
            gridApi.addEventListener("cellKeyPress", this.cellKeyPress);
        }, 1000);
    },
    beforeDestroy() {
        const gridApi = this.$refs.agGrid.gridApi;
        gridApi.removeEventListener("cellKeyPress", this.cellKeyPress);
    },
    methods: {
        onCellClicked(params) {
            if (params.column.colId == "no") {
                const rowIndex = params.rowIndex;
                const gridApi = this.$refs.agGrid.gridApi;
                gridApi.startEditingCell({ rowIndex, colKey: params.column.colId });
            }
        },
        async onItemClicked(params) {
            if (params.colDef !== undefined) {
                if (params.colDef.field !== 'no') {
                    const routeData = this.$router.resolve({ name: 'supervisor-item-sheet', params: { code: params.data.code } });
                    window.open(routeData.href, '_blank');
                }
            } else {
                const routeData = this.$router.resolve({ name: 'supervisor-item-sheet', params: { code: params.code } });
                window.open(routeData.href, '_blank');
            }
        },
        async filterClicked(filter) {
            // console.log(filter);
            if (filter.selectedCompany != null) {
                this.showLoading = true;
                let response = await this.$http.get("_item/matching-items?company=" + filter.selectedCompany);
                this.agGrid.rows = response.data;
                this.showLoading = false;
            }
        },
        async cellKeyPress(event) {
            if (event.event.key === "Enter") {
                const focusedCell = this.$refs.agGrid.gridOptions.api.getFocusedCell();
                if (focusedCell && focusedCell.column.colId === "no") {
                    this.$swal({
                        title: 'Êtes vous sûr de modifier le mappage de cet article ?',
                        text: "Vous ne pourrez pas revenir en arrière !",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Oui, modifiez-le !',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(async (result) => {
                        if (result.value) {
                            let obj = {
                                company: event.data.company,
                                itemNo: event.data.no,
                                itemCode: event.data.code,
                            }
                            this.showLoading = true;
                            await this.$http.post("_item/matchItem", obj).catch(() => {
                                this.showLoading = false;
                            });
                            var rowNodee = this.$refs.agGrid.gridOptions.api.getDisplayedRowAtIndex(event.rowIndex);
                            this.$refs.agGrid.gridOptions.api.flashCells({
                                rowNodes: [rowNodee],
                                columns: ['no'],
                            });
                            this.$refs.agGrid.gridOptions.api.refreshCells()
                            this.showLoading = false;
                        }
                    })
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped></style>